function initFaq() {
  const faqActions = document.querySelectorAll('.faq__action');
  const faqContent = document.querySelectorAll('.faq__content');

  faqActions.forEach((faqAction, i) => {
    faqAction.addEventListener('click', () => {
      faqAction.classList.add('is-active');
      faqContent[i].classList.add('is-active');

      faqActions.forEach((otherAction, j) => {
        if (otherAction !== faqAction) {
          otherAction.classList.remove('is-active');
          faqContent[j].classList.remove('is-active');
        }
      })
    })
  })
}

export { initFaq }
