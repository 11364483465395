function initWelcome() {
  const welcomeMenuClose = document.querySelector('.welcome__menu-close');
  const welcomeMenuToggle = document.querySelector('.welcome__menu-toggle');
  const welcomeMenu = document.querySelector('.welcome__menu-wrapper');
  const menuLinks = document.querySelectorAll('.welcome__menu-link');

  // Открытие меню по клику на кнопку
  welcomeMenuToggle.addEventListener('click', (event) => {
    welcomeMenu.classList.toggle('is-active');
    event.stopPropagation(); // Останавливаем всплытие события, чтобы клик по кнопке не закрывал меню
  });

  // Закрытие меню по клику на кнопку закрытия
  welcomeMenuClose.addEventListener('click', () => {
    welcomeMenu.classList.remove('is-active');
  });

  // Закрытие меню при клике вне его области
  document.addEventListener('click', (event) => {
    const isClickInsideMenu = welcomeMenu.contains(event.target);
    const isClickOnToggle = welcomeMenuToggle.contains(event.target);

    if (!isClickInsideMenu && !isClickOnToggle) {
      welcomeMenu.classList.remove('is-active');
    }
  });

  // Закрытие меню при клике на ссылки в меню
  menuLinks.forEach((link) => {
    link.addEventListener('click', () => {
      welcomeMenu.classList.remove('is-active');
    });
  });
}

export { initWelcome }
