function initHeader() {
  const headerMenuToggle = document.querySelector('.header__toggle');
  const header = document.querySelector('.header');
  const overlay = document.querySelector('.overlay');
  const menuLinks = document.querySelectorAll('.header__menu-link');

  // Обработка клика по кнопке открытия меню
  headerMenuToggle.addEventListener('click', (event) => {
    header.classList.toggle('is-active');
    overlay.classList.toggle('is-active');
    event.stopPropagation(); // Останавливаем всплытие события, чтобы клик по кнопке не закрывал меню
  });

  // Закрытие меню при клике вне его области
  document.addEventListener('click', (event) => {
    const isClickInsideMenu = header.contains(event.target);

    if (!isClickInsideMenu) {
      header.classList.remove('is-active');
      overlay.classList.remove('is-active');
    }
  });

  // Обработка клика на ссылки в меню
  menuLinks.forEach((link) => {
    link.addEventListener('click', () => {
      header.classList.remove('is-active');
      overlay.classList.remove('is-active');
    });
  });

  // Обработка скролла для изменения состояния хедера
  window.addEventListener('scroll', () => {
    header.classList.toggle('is-scrolled', window.scrollY > document.documentElement.clientHeight / 2);
  });
}

export { initHeader }
